import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NavBarComponent} from "./components/nav-bar/nav-bar.component";
import { FooterComponent } from './components/footer/footer.component';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { DiscoverComponent } from '../home/discover/discover.component';
import {ButtonModule} from "primeng/button";
import { EventDetailComponent } from '../home/event-detail/event-detail.component';
import { ExploreSectionComponent } from './explore-section/explore-section.component';
import { TopEventsComponent } from './top-events/top-events.component';
import {RippleModule} from "primeng/ripple";
import { AllCategoriesComponent } from './all-categories/all-categories.component';
import { TopEventComponent } from './components/top-event/top-event.component';
import { TodaysEventsComponent } from './todays-events/todays-events.component';
import { TodayEventComponent } from './components/today-event/today-event.component';
import { RecommendedEventsComponent } from './recommended-events/recommended-events.component';
import { EventsListComponent } from '../home/events-list/events-list.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { SelectButtonModule } from 'primeng/selectbutton';
import { FormsModule } from '@angular/forms';
import { DataViewModule } from 'primeng/dataview';
import { RatingModule } from 'primeng/rating';
import { InputTextModule } from 'primeng/inputtext';
import { PartnersComponent } from '../home/partners/partners.component';
import { DialogModule } from 'primeng/dialog';
import { ChipModule } from 'primeng/chip';
import { LottieComponent } from 'ngx-lottie';
import { AnimatedIconComponent } from './animated-icon/animated-icon.component';
import { SuperSearchComponent } from './super-search/super-search.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { SimilarEventsComponent } from './similar-events/similar-events.component';
import { ExploreEventCategoriesComponent } from './explore-event-categories/explore-event-categories.component';
import { StyleClassModule } from 'primeng/styleclass';
import { ToastModule } from 'primeng/toast';
import { SliderModule } from 'primeng/slider';
import { InputNumberModule } from 'primeng/inputnumber';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TranslocoDirective } from '@jsverse/transloco';
import { LanguagePickerComponent } from './components/language-picker/language-picker.component';
import { CityOverviewComponent } from '../home/city-overview/city-overview.component';



@NgModule({
  declarations: [
    NavBarComponent,
    FooterComponent,
    DiscoverComponent,
    EventDetailComponent,
    ExploreSectionComponent,
    TopEventsComponent,
    AllCategoriesComponent,
    TopEventComponent,
    TodaysEventsComponent,
    TodayEventComponent,
    RecommendedEventsComponent,
    EventsListComponent,
    PartnersComponent
  ],
	imports: [
		CommonModule,
		RouterLink,
		ButtonModule,
		RippleModule,
		MultiSelectModule,
		CalendarModule,
		DropdownModule,
		SelectButtonModule,
		FormsModule,
		DataViewModule,
		RatingModule,
		InputTextModule,
		RouterLinkActive,
		DialogModule,
		ChipModule,
		ToastModule,
		LottieComponent,
		AnimatedIconComponent,
		SuperSearchComponent,
		TruncatePipe,
		SimilarEventsComponent,
		ExploreEventCategoriesComponent,
		StyleClassModule,
		SliderModule,
		InputNumberModule,
		AutoCompleteModule,
		TranslocoDirective,
		LanguagePickerComponent,
		CityOverviewComponent,
	],
  exports: [
    NavBarComponent,
    FooterComponent,
    DiscoverComponent,
    ExploreSectionComponent,
  ],
})
export class SharedModule { }
